$primary: #2e5266;
$primary-contrast: #fff;
$secondary: #2e3532;
$secondary-contrast: #fff;

$grid-hovered-bg: $primary;
$grid-hovered-text: $primary-contrast;
$grid-selected-bg: $primary;
$grid-selected-text: $primary;

// Kendo
@import "~@progress/kendo-theme-material/dist/all.scss";

.k-grid tr.k-state-selected > td {
  background-color: $white;
  color: $primary;
}

.k-grid-header-wrap > table > thead > tr,
.k-grid table tr {
  &:hover {
    background-color: rgba($secondary, 0.5);
    color: $secondary-contrast;
  }
}
.k-editor {
  line-height: inherit;
}

.k-pager-numbers .k-link {
  margin: 0 2px 0 0;
  padding: 10px;
}

.k-pager-numbers .k-link.k-state-selected {
  border-radius: 50px;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 50px;
  /* Firefox 1-3.6 */
  -moz-border-radius: 50px;
}

.k-grid-content {
  overflow-y: hidden;
}

.k-grid-header {
  padding: 0 !important;
}

// remove table borders
.k-grid,
.k-grid-header-wrap,
.k-grid-header th,
.k-grid-content > table > tbody > tr > td {
  border: 0 !important;
}

.k-pager-wrap {
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
}

// ref: SHP-4454
// .k-combobox .k-dropdown-wrap .k-clear-value {
//   right: 20px;
// }

// move dropdown in front of dialog
.k-animation-container {
  z-index: 10003; // set the desired value based on the application requirements
}

// word wrap cell content
.k-grid td {
  word-wrap: break-word;
  max-width: 100%;
}

// user details tab "view" styles
.k-hierarchy-cell {
  display: none;
}

.k-grid table tr.k-detail-row {
  background-color: white;
  &:hover {
    background-color: white;
    color: black;
  }
}

// color picker border
.k-colorpicker .k-picker-wrap {
  border-color: #fff;
  background-color: transparent;
}

// number field input label
.k-textbox-container > .k-label {
  text-overflow: clip !important;
  overflow: visible !important;
}
